<template>
  <div id="inspire">
    <div>
      <div fluid class="backgroundWindow flex justify-center items-center p-0 h-screen px-4">
        <div class="bg-white text-text1 card rounded-xl w-full p-6 pb-4 sm:m-0 card_design3 max-h-screen overflow-auto">
          <div class="flex-wrap">
            <div class="text-left">
              <div class="mb-3 p-0 ">
                <div class="text-center flex items-center justify-center">
                  <img src="@/assets/images/ZizbeyJobsLogo.svg" />
                  <div class="heading-2 text-primary font-semibold pl-1">ZizbeyJobs</div>
                </div>
                <div class="text-gray4">
                  {{stepNo1 ? '• Step 1 of 2': '• Step 2 of 2'}}
                </div>
                <div v-if="stepNo1">
                  <div class=" font-semibold heading-4 text-center py-4 pt-0">
                  Owner Registration
                  </div>
                  <div class="mb-4">
                     <TextField
                      :inputId="`ownerfnameinputid`"
                      :textMaxlength="100"
                      :showcharLimit="true"
                      :fieldError="errObj.fnameErr !== ''"
                      :inputext="orgObj.firstname"
                      :placholderText="`Enter Your First Name`"
                      :lableText="'First Name'"
                      :autoFocus="false"
                      :inputType="'text'"
                      @keyPressAction="errObj.fnameErr = ''"
                      @inputChangeAction="(data) => orgObj.firstname = data"  />
                      
                      <p class="text-error"> {{ errObj.fnameErr }}</p>
                  </div>
                  <div class="mb-4">
                     <TextField
                      :inputId="`ownernameinputid1`"
                      :textMaxlength="100"
                      :showcharLimit="true"
                      :inputext="orgObj.lastname"
                      :placholderText="`Enter Your Last Name`"
                      :lableText="'Last Name'"
                      :autoFocus="false"
                      :inputType="'text'"
                      @inputChangeAction="(data) => orgObj.lastname = data"  />
                  </div>
                  <div class="mb-4">
                     <TextField
                      :inputId="`organizationNameinput`"
                      :textMaxlength="100"
                      :showcharLimit="true"
                      :fieldError="errObj.orgError !== ''"
                      :inputext="orgObj.organizationName"
                      :placholderText="`Enter Organization Name`"
                      :lableText="'Organization Name'"
                      :autoFocus="false"
                      :inputType="'text'"
                      @keyPressAction="errObj.orgError = ''"
                      @inputChangeAction="(data) => orgObj.organizationName = data"  />
                      
                      <p class="text-error"> {{ errObj.orgError }}</p>
                  </div>
                  <div class="mb-4">
                     <TextField
                      :inputId="`owneremailinputid`"
                      :inputext="orgObj.emailaddress"
                      :placholderText="`Enter a valid email address`"
                      :lableText="'Email Address'"
                      :fieldError="errObj.emailErr !== '' || emailExist"
                      :autoFocus="false"
                      :inputType="'text'"
                      @keyPressAction="errObj.emailErr = ''; emailExist = false"
                      @blurAction="emailHandler(orgObj.emailaddress)"
                      @inputChangeAction="(data) => orgObj.emailaddress = data"  />
                      
                      <p class="text-error"> {{ errObj.emailErr }}</p>
                      <p v-if="emailExist" class="text-error">Email already in use, please use another</p>
                  </div>
                  <div class=" mb-4">
                    <TextField 
                    :textMaxlength="4"
                    :fieldError="errObj.setPinValueErr !== ''"
                    @keyPressAction="errObj.setPinValueErr = ''"
                    :inputId="`pinsetinpuvalur`"
                    :inputext="orgObj.loginPin"
                    :placholderText="`Pin`"
                    :lableText="'Enter 4 Digit Pin (used for login)'"
                    :autoFocus="false"
                    :inputType="'password'"
                    @inputChangeAction="(data) => orgObj.loginPin = data"  />
                    <p class="text-error"> {{ errObj.setPinValueErr }}</p>
                  </div>
                  <div class="py-2">
                    <Button  class=" h-10 w-full flex justify-center" :btnSize="'medium'" :textColor="'white'" :btnColor="'primary'" :btnText="'Next'" @buttonAction="nextCallRegister()"/>
                  </div>
                  <div class="text-center mt-2">
                    <div class="">Already have an account? </div>
                    <span @click="$router.push({name: 'login'})" class="cursor-pointer  font-semibold text-primary">Click here to Login</span>
                  </div>
                  <div>
                    <div class="py-2">
                      <div>By clicking Next you agree to our <span class="text-primary cursor-pointer" @click="routingtoLink('privacy')">Privacy Policy</span> & <span class="text-primary cursor-pointer" @click="routingtoLink('terms')">Terms of Service</span></div>
                    </div>
                  </div>
                </div>
                <div v-if="!stepNo1">
                  <div class="font-semibold heading-3 text-center pt-1">
                  Hi {{registerObj.firstName}}!
                  </div>
                  <div class="font-semibold heading-3 text-center pb-4 ">
                  Let's Set up your Company
                  </div>
                  <div class="mb-4 flex gap-2">
                    <div class="w-32 relative">
                      <p class="label_css">Code</p>
                      <div type="text" @click="clickOnCode()"
                        class="flex items-center cursor-pointer outline-none border border-gray2 text-text1 rounded cust_text_field w-full pl-2 p-2.5 focus:border-primary focus:ring-1 focus:ring-primary overFlowParaA" style="height: 50px !important;" 
                        placeholder="">{{ secondObj.callingCode }}</div>
                        <div class="absolute top-1/2 right-4 text-gray4"> <i class="fas fa-caret-down"></i></div>
                      </div>
                      <div class="w-full">
                         <NumberInput
                          :inputId="'ownercontnumberid'"
                          :inputext="secondObj.cellNumber"
                          :textMaxlength="12"
                          :placholderText="`Enter Mobile Number`"
                          :lableText="'Mobile Number'"
                          :inputType="'text'"
                          :autoFocus="false"
                          :fieldError="errObj.numberErr !== '' || numberExist"
                          :setReadOnly="false"
                          @keyPressAction="errObj.numberErr = ''; numberExist = false"
                          @blurAction="checkContactNumber('other')"
                          @inputChangeAction="(data) => secondObj.cellNumber = data"  />
                        <p class="text-error">{{errObj.numberErr}}</p>
                      <p v-if="numberExist" class="text-error">Number already in use, please use another</p>
                    </div>
                  </div>
                  <div class="mb-4 relative">
                    <p class="label_css">Company Industry</p>
                    <div type="text" @click="openCompInd()"
                    :class="errObj.compnanyTypeErr ? 'border border-error' : ' border border-gray2'"
                      class="flex items-center cursor-pointer outline-none border text-text1 rounded cust_text_field w-full pl-2 p-2.5 focus:border-primary focus:ring-1 focus:ring-primary overFlowParaA" style="height: 50px !important;" 
                      placeholder="Please select Industry">{{ secondObj.selectedCompanyName }}
                    </div>
                    <div class="absolute top-1/2 right-4 text-gray4"> <i class="fas fa-caret-down"></i></div>
                  </div>
                  <p class="text-error -mt-4 mb-1" v-if="errObj.compnanyTypeErr !== ''"> {{ errObj.compnanyTypeErr }}</p>
                  <div class="mb-4 relative">
                    <p class="label_css">Company Size</p>
                    <div type="text" @click="openCompSize()"
                      :class="errObj.compnanyErr ? 'border border-error' : ' border border-gray2'"
                      class="flex items-center cursor-pointer outline-none text-text1 rounded cust_text_field w-full pl-2 p-2.5 focus:border-primary focus:ring-1 focus:ring-primary overFlowParaA" style="height: 50px !important;" 
                      placeholder="Select Company Size">{{ secondObj.companySize }}
                    </div>
                    <div class="absolute top-1/2 right-4 text-gray4"> <i class="fas fa-caret-down"></i></div>
                  </div>
                  <p class="text-error -mt-4 mb-1" v-if="errObj.compnanyErr !== ''"> {{ errObj.compnanyErr }}</p>
                  <div class="mb-4 relative">
                    <p class="label_css">What is your estimated annual revenue</p>
                    <div type="text" @click="openEstAnnual()"
                      class="flex items-center cursor-pointer outline-none border border-gray2 text-text1 rounded cust_text_field w-full pl-2 p-2.5 focus:border-primary focus:ring-1 focus:ring-primary overFlowParaA" style="height: 50px !important;" 
                      placeholder="Select Option">{{ secondObj.estimatedAnnualRevenue }}
                    </div>
                    <div class="absolute top-1/2 right-4 text-gray4"> <i class="fas fa-caret-down"></i></div>
                  </div>
                  <div class="mb-4">
                     <TextField
                      :inputId="`ownernameinputid2`"
                      :showcharLimit="false"
                      :inputext="secondObj.source"
                      :placholderText="``"
                      :lableText="'How did you hear about ZizbeyJobs'"
                      :autoFocus="false"
                      :inputType="'text'"
                      @inputChangeAction="(data) => secondObj.source = data"  />
                  </div>
                  <div class="py-2">
                    <Button  class=" h-10 w-full flex justify-center" :btnSize="'medium'" :textColor="'white'" :btnColor="'primary'" :btnText="'Finish'" @buttonAction="finishteps()"/>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <loader
      v-if="$store.getters.showLoader"
      v-bind:data="$store.getters.loaderMessage"
    ></loader>
    <snakBar
        v-if="$store.getters.showAlert"
        v-bind:status="$store.getters.showAlert"
        v-bind:message="$store.getters.alertMessage"
        v-bind:color="$store.getters.alertColor"
      >
      </snakBar>
      <div v-if="openTimePopup" class="popup_overlay px-4">
        <div class="custom_dialog rounded-xl" style="width: 450px; max-height: 85%;">
          <div class="bg-primary flex p-3 text-center justify-center sticky top-0">
            <div class=" font-medium text-white heading-3">
              Select Country
            </div>
          </div>
          <div style="max-height: 60vh">
            <div class="p-2">
              <div class="mb-2">
                <SearchTextInput @searchCall="searchCall" :placeholderText="`Search ...`"/>
              </div>
            </div>
            <div  style="max-height: 50vh" class="overflow-auto p-2 px-4">
              <div v-for="(data, index) in parentCityList" :key="index">
                <div class="flex items-center py-2" :id="data.phoneCode">
                  <input type="radio" class="w-5 h-5 text-blue-600 bg-gray-100 border-gray-300 cursor-pointer" v-model="radioModel" name="radio1" :value="data.phoneCode" @click="clickOnRadio(data)">
                  <div class="pl-3">
                    <div class="flex text-text2 "><p>{{data.countryName}} ({{data.phoneCode}})</p></div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class=" sticky bottom-0 bg-white w-full flex justify-end pr-3 pt-2">
            <div class="text-rigth flex gap-2 mb-3">
              <Button :btnSize="'medium'" :textColor="'text1'" :btnColor="'gray1'" :btnText="'Discard'" @buttonAction="openTimePopup = false"/>
            </div>
          </div>
        </div>
      </div>
      <div v-if="openCompIndPopup" class="popup_overlay px-4">
        <div class="custom_dialog rounded-xl" style="width: 450px; max-height: 85%;">
          <div class="bg-primary flex p-3 text-center justify-center sticky top-0 font-medium text-white heading-3">
            Company Industry
          </div>
          <div style="max-height: 60vh" class="overflow-auto p-2 px-4">
              <div v-for="(data, index) in requireListing.industryList" :key="index">
                <div class="flex items-center py-2 w-max" @click="closeCompInd(data)">
                  <input type="radio" class="w-5 h-5 text-blue-600 bg-gray-100 border-gray-300 cursor-pointer" v-model="radioCompInd" name="radio2" :value="data.industryTypeName" >
                  <div class="flex text-text2 pl-3 cursor-pointer"><p>{{data.industryTypeName}}</p></div>
                </div>
              </div>
            </div>
          <div class=" sticky bottom-0 bg-white w-full flex justify-end pr-3">
            <div class="text-right flex gap-2 mr-3 mb-3">
              <Button :btnSize="'medium'" :textColor="'text1'" :btnColor="'gray1'" :btnText="'Discard'" @buttonAction="openCompIndPopup = false"/>
            </div>
          </div>
        </div>
      </div>
      <div v-if="openCompSizePopup" class="popup_overlay px-4">
        <div class="custom_dialog rounded-xl" style="width: 450px; max-height: 85%;">
          <div class="bg-primary flex p-3 text-center justify-center sticky top-0 font-medium text-white heading-3">
            Company Size
          </div>
          <div style="max-height: 60vh" class="overflow-auto p-2 px-4">
              <div v-for="(data, index) in requireListing.companySizeList" :key="index">
                <div class="flex items-center py-2 w-max" @click="closeCompSize(data)">
                  <input type="radio" class="w-5 h-5 text-blue-600 bg-gray-100 border-gray-300 cursor-pointer" v-model="radioSize" name="radio3" :value="data.companySize">
                  <div class="flex text-text2 pl-3 cursor-pointer"><p>{{data.companySize}}</p></div>
                </div>
              </div>
            </div>
          <div class=" sticky bottom-0 bg-white w-full flex justify-end pr-3">
            <div class="text-right flex gap-2 mr-3 mb-3">
              <Button :btnSize="'medium'" :textColor="'text1'" :btnColor="'gray1'" :btnText="'Discard'" @buttonAction="openCompSizePopup = false"/>
            </div>
          </div>
        </div>
      </div>
      <div v-if="openEstAnnualPopup" class="popup_overlay px-4">
        <div class="custom_dialog rounded-xl" style="width: 450px; max-height: 85%;">
          <div class="bg-primary flex p-3 text-center justify-center sticky top-0 font-medium text-white heading-3">
            What is your estimated annual revenue
          </div>
          <div style="max-height: 60vh" class="overflow-auto p-2 px-4">
              <div v-for="(data, index) in requireListing.estimateAnnRevList" :key="index">
                <div class="flex items-center py-2 w-max" @click="closeEstAnnual(data)">
                  <input type="radio" class="w-5 h-5 text-blue-600 bg-gray-100 border-gray-300 cursor-pointer" v-model="radioEstAnnual" name="radio4" :value="data.revenue" >
                  <div class="flex text-text2 pl-3 cursor-pointer"><p>{{data.revenue}}</p></div>
                </div>
              </div>
            </div>
          <div class=" sticky bottom-0 bg-white w-full flex justify-end pr-3">
            <div class="text-right flex gap-2 mr-3 mb-3">
              <Button :btnSize="'medium'" :textColor="'text1'" :btnColor="'gray1'" :btnText="'Discard'" @buttonAction="openEstAnnualPopup = false"/>
            </div>
          </div>
        </div>
      </div>
  </div>
</template>
<script>
import {setCookiesWithExpire} from '@/utils/cookies.js'
import axios from 'axios'
import SearchTextInput from '@/View/components/searchTextInput.vue'
import NumberInput from '@/View/components/number-text-field.vue'
import Loader from '@/View/components/Loader.vue'
import snakBar from '@/View/components/SnakBar.vue'
import TextField from '@/View/components/textfield.vue'
import Button from '@/View/components/globalButton.vue'
import MyJobApp from '@/api/MyJobApp.js'
import {validateEmail, checkSpeialChar} from '@/utils/validation.js'
export default {
  components: {
    SearchTextInput,
    NumberInput,
    Loader,
    snakBar,
    TextField,
    Button,
  },
  data () {
    return {
      registerObj: null,
      requireListing: [],
      selectedTimeZone: {},
      timeZoneList: [],
      parentCityList: [],
      openTimePopup: false,
      openCompIndPopup: false,
      openCompSizePopup: false,
      openEstAnnualPopup: false,
      radioModel: '',
      radioCompInd: '',
      radioSize: '',
      radioEstAnnual: '',
      stepNo1: true,
      setPinDisabled: true,
      isRegistrationSuccess: false,
      isSetAppPin: false,
      isChangeEmail: false,
      countDown: 30,
      emailExist: false,
      numberExist: false,
      confirmPin: '',
      setPinValue: '',
      otpValue: '',
      emailValid: false,
      agreeTerms: false,
      searchForJob: '',
      orgObj: {
        firstname: '',
        lastname: '',
        emailaddress: '',
        countryCode: '',
        organizationName: '',
        loginPin: '',
        requestPlatFrom: 'web',
      },
      secondObj: {
        countryCode: '',
        deviceCountryCode: '',
        callingCode: '+91',
        cellNumber: '',
        industryTypeId: 0,
        selectedCompanyName: '',
        companySize:'',
        estimatedAnnualRevenue: '',
        source: '',
        timeZone: ''
      },
      errObj: {
        orgError: '',
        fnameErr: '',
        numberErr: '',
        numCodeErr: '',
        emailErr: '',
        otpErr: '',
        setPinValueErr: '',
        confirmPinErr: '',
        compnanyErr: '',
        compnanyTypeErr: '',
      }
    }
  },
  beforeMount () {},
  created () {},
  watch: {
  },
  mounted () {
    let value = localStorage.getItem('isFormFilled')
    let value2 = localStorage.getItem('jobUserInfo')
    console.log('value', value)
    if (value) {
      this.stepNo1 = false
      console.log('yes')
    } else {
      console.log('no')
    }
    if (value2) {
      this.registerObj = JSON.parse(value2)
    } else {
      this.registerObj = null
    }
    this.getCoutryCode()
    this.getAllCompnayDetail()
    this.getLoginIpData()
  },
  destroyed () {
  },
  methods: {
    routingtoLink (value) {
      let url = ''
      if (value === 'terms') {
        url = 'https://www.zizbeyjobs.com/terms'
      } else {
        url = 'https://www.zizbeyjobs.com/privacy-policy'
      }
      window.open(url, '_blank')
    },
    nextCallRegister () {
        let isValid = true
        if (this.orgObj.firstname.trim() === '') {
            isValid = false
            this.errObj.fnameErr = "First name is required"
        }
        if (this.emailExist || this.errObj.emailErr !== '') {
            isValid = false
        }
        if (this.orgObj.emailaddress.trim() === '') {
            isValid = false
            this.errObj.emailErr = "Email address is required"
        }
        if (this.orgObj.organizationName.trim() === '') {
            isValid = false
            this.errObj.orgError = "Organization name is required"
        }
        if (this.orgObj.loginPin.trim() === '') {
            isValid = false
            this.errObj.setPinValueErr = "Required"
        } else if (this.orgObj.loginPin.length <= 3) {
            isValid = false
            this.errObj.setPinValueErr = "Login pin must be at least 4 digit"
        }
        if (isValid) {
        this.$store.dispatch('SetLoader', {status: true, message: 'Please wait....'})
        console.log('calll api ');
        MyJobApp.registerOrganization(
          this.orgObj,
          (response) => {
            this.stepNo1 = false
            localStorage.setItem('jobUserInfo', JSON.stringify(response.Data))
            this.registerObj = response.Data
            localStorage.setItem('isFormFilled', true)
            setCookiesWithExpire('jobAuthToken', response.Data.authToken, 30)
            this.$store.dispatch('SetAlert', {showAlert: true, message: response.message, color: 'success'})
            this.$store.dispatch('SetLoader', {status: false, message: ''})
          },
          error => {
            this.$store.dispatch('SetLoader', {status: false, message: ''})
            this.$store.dispatch('SetAlert', {showAlert: true, message: error.message, color: 'error'})
          }
        ) 
      }
    },
    checkExist (from) {
        MyJobApp.checkEmailNumberExist(
            from === 'number' ? '' : this.orgObj.emailaddress,
            from === 'email' ? '' : this.secondObj.cellNumber,
            this.secondObj.callingCode,
          response => {
            if (from === 'number' && response.Data) {
                this.numberExist = true
            }
            if (from === 'email' && response.Data) {
                this.emailExist = true
            }
          },
          error => {
            this.$store.dispatch('SetLoader', {status: false, message: ''})
            this.$store.dispatch('SetAlert', {showAlert: true, message: error.message, color: 'error'})
          }
        ) 
    },
    getCoutryCode () {
        MyJobApp.GetPhoneCountryCode(
          (response) => {
            let temp =  response.Data !== null ? response.Data : []
            temp.forEach(element => {
              if (element.phoneCode.substr(1) !== '+') {
                element.phoneCode = `+${element.phoneCode}`
              }
            });
            this.timeZoneList = temp
            this.parentCityList = temp
            console.log('temp', temp)
            this.$store.dispatch('SetLoader', {status: false, message: ''})
          },
          error => {
            this.$store.dispatch('SetLoader', {status: false, message: ''})
            this.$store.dispatch('SetAlert', {showAlert: true, message: error.message, color: 'error'})
          }
        ) 
    },
    getAllCompnayDetail () {
        MyJobApp.GetIndustryDetail(
          (response) => {
            console.log('response', response)
            this.requireListing = response.Data !== null ? response.Data : [] 
            this.$store.dispatch('SetLoader', {status: false, message: ''})
          },
          error => {
            this.$store.dispatch('SetLoader', {status: false, message: ''})
            this.$store.dispatch('SetAlert', {showAlert: true, message: error.message, color: 'error'})
          }
        ) 
    },
    emailHandler (emailId) {
        this.emailExist = false
        if (emailId.trim() !== '') {
            this.errObj.emailErr = this.emailValidation(emailId)
        }
    },
     emailValidation (emailId) {
      if (emailId === '') {
        return 'Email address is required'
      } else if (validateEmail(emailId) === false) {
        return 'Email address is invalid'
      } else {
          this.checkExist('email')
        return ''
      }
    },
    checkContactNumber () {
      this.numberExist = false
      let value = checkSpeialChar(this.secondObj.cellNumber)
      if (value) {
        this.errObj.numberErr = 'Please enter valid Mobile number'
      } else {
        this.errObj.numberErr = ''
      }
      if (this.secondObj.cellNumber !== '' ) {
        this.checkExist('number')
      }
    },
    clickOnCode () {
      this.radioModel = this.secondObj.callingCode
      this.openTimePopup = true
      setTimeout(() => {
        document.getElementById(this.secondObj.callingCode).scrollIntoView({behavior: "smooth",block: 'center'})
      }, 170);
    },
    clickOnRadio (data) {
      this.secondObj.callingCode = data.phoneCode
      this.secondObj.countryCode = data.iso2
      this.parentCityList = this.timeZoneList
      setTimeout(() => {
        this.openTimePopup = false
      }, 200);
    },
    searchCall (data) {
      if (data.length === 0) {
          this.parentCityList = this.timeZoneList
        } else {
          let filter = data.toLowerCase();
          this.parentCityList = []
          this.timeZoneList.forEach(ELE => {
            if (data.toLowerCase().indexOf(filter) > -1 === ELE.countryName.toLowerCase().indexOf(filter) > -1) {
              this.parentCityList.push(ELE)
            }
          })
        }
    },
    finishteps () {
      let isError = false
      // if (this.secondObj.companySize === '') {
      //   this.errObj.compnanyErr = 'Please select company size'
      //   isError = true
      // }
      // if (this.secondObj.selectedCompanyName === '') {
      //   this.errObj.compnanyTypeErr = 'Please select company industry type'
      //   isError = true
      // }
      if (!isError) {
        this.$store.dispatch('SetLoader', {status: true, message: 'Please wait....'})
        MyJobApp.CompanySetup(
          this.secondObj,
          (response) => {
            localStorage.removeItem('isFormFilled')
            this.$router.push({name: 'dashboard1'})
            this.$store.dispatch('SetAlert', {showAlert: true, message: response.message, color: 'success'})
            this.$store.dispatch('SetLoader', {status: false, message: ''})
          },
          error => {
            this.$store.dispatch('SetLoader', {status: false, message: ''})
            this.$store.dispatch('SetAlert', {showAlert: true, message: error.message, color: 'error'})
          }
        ) 
      }
    },
    closeCompInd (data) {
      this.radioCompInd = data.industryTypeName
      this.secondObj.industryTypeId = data.industryTypeId
      this.secondObj.selectedCompanyName = data.industryTypeName
      setTimeout(() => {
        this.errObj.compnanyTypeErr = ''
        this.openCompIndPopup = false
      }, 500);
    },
    closeCompSize (data) {
      this.radioSize = data.companySize
      this.secondObj.companySize = data.companySize
      setTimeout(() => {
        this.errObj.compnanyErr = ''
        this.openCompSizePopup = false
      }, 200);
    },
    closeEstAnnual (data) {
      this.radioEstAnnual = data.revenue
      this.secondObj.estimatedAnnualRevenue = data.revenue
      setTimeout(() => {
        this.openEstAnnualPopup = false
      }, 200);
    },
    openCompInd () {
      this.radioCompInd = this.secondObj.selectedCompanyName
      this.openCompIndPopup = true
    },
    openCompSize () {
      this.radioSize = this.secondObj.companySize
      this.openCompSizePopup = true
    },
    openEstAnnual () {
      this.radioEstAnnual = this.secondObj.estimatedAnnualRevenue
      this.openEstAnnualPopup = true
    },
    getLoginIpData () {
      let timeZone = Intl.DateTimeFormat().resolvedOptions().timeZone
      if (timeZone === 'Asia/Calcutta') {
        this.secondObj.timeZone = 'Asia/Kolkata'
      } else {
        this.secondObj.timeZone = timeZone
      }
      axios.get(`https://ipapi.co/json/`)
      .then(data => {
        console.log('data------->', data)
        if (data.status === 200) {
          this.orgObj.countryCode = data.data.country
          if (data.data.country.toLowerCase() === 'in' || data.data.country === 'us' || data.data.country === 'gb') {
            this.secondObj.deviceCountryCode = data.data.country
          } else {
            this.secondObj.deviceCountryCode = 'OTH'
          }
          this.secondObj.countryCode = data.data.country_code
          this.secondObj.callingCode = data.data.country_calling_code
        } else {
          this.secondObj.callingCode = '+91'
        }
        console.log('this.secondObj.callingCode', this.secondObj.callingCode)
      }).catch(Error => {
        console.log('Error', Error)
      })
    },
  },
  beforeDestroy () {},
}
</script>
<style>
@import url("https://fonts.googleapis.com/css?family=Saira+Stencil+One&display=swap");
.backgroundWindow {
  background: url('https://workapp-public-download.s3.ap-south-1.amazonaws.com/assets/signup-in-background.jpg');
  background-size: cover;
  background-position: center
}
.card_design3 {
  max-width: 600px;
}

</style>
